import React, { useState, useCallback, useEffect, useRef } from "react";
import publicationJson from "../config/Publications.json";
import axios from "axios";
import JSCookie from "js-cookie";
import moment from "moment";
import publicIp from "public-ip";
import { useHistory } from "react-router-dom";

import dayjs, { Dayjs } from "dayjs";
import { eventIntegration, profilePush } from "../components/clevertap";
// import isBetween from "dayjs/plugin/isBetween";
// dayjs.extend(isBetween);
export const AppContext = React.createContext();

const AppContextProvider = (props) => {
  const history = useHistory();

  let dateKeyJsonFilename = "DateIndex";
  if (process.env.REACT_APP_ENV === "PROD") {
    dateKeyJsonFilename = "datekey";
  }
  const [isLandingPage, setIsLandingPage] = useState(true);
  const [TTS, setTTS] = useState("");
  const [plaerLoading, setPlayerLoading] = useState(false);
  const [ttsMp3, setTtsMp3] = useState("");
  const [openAudioTray, setIsOpenAudioTray] = useState(false);
  const [stepsEnabled, setstepsEnabled] = useState(false);
  const todayCurrentDate = dayjs(new Date()).format("DD-MM-YYYY");
  const [IPData, setIPData] = useState({});
  const [isPopularDrawerOpen, setIsPopularDrawerOpen] = useState(true);
  const [isFitToHeight, setIsFitToHeight] = useState(0);
  const [isFitToWidth, setisFitToWidth] = useState(false);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [articles, setArticles] = useState([]);
  const [allPlayersDigi, setAllPlayersDigi] = useState([]);
  const [mergedLinkArrays, setMergedLinkArrays] = useState({});
  const [allowInterStitial, setAllowInterStitial] = useState(false);
  const [articles2] = useState();
  const [articlesPage2, setArticlesPage2] = useState([]);
  const [widthPage2, setWidthPage2] = useState(0);
  const [isEnableAnalytics, setIsEnableAnalytics] = useState("Enable");
  const [isEnableAds, setIsEnableAds] = useState("Enable");
  const [paperView, setPaperView] = useState("replica");
  const [multiplier, setMultiplier] = useState(0);
  const [digiFinalIndexData, setDigiFinalIndexData] = useState([]);
  const [activeDigiToc, setActiveDigiToc] = useState("");
  const [firstViewValueDig, setFirstViewValueDig] = useState(false);
  const [currentIndexStory, setCurrentIndexStory] = useState(0);
  const [graphsArticle, setGraphsArticle] = useState(false);
  const [downloadPrint, setDownloadPrint] = useState(false);
  const [isOpenInstaStories, setIsOpenInstaStories] = useState(false);
  const [isOpenSlideShow, setIsOpenSlideShow] = useState(false);
  const [isOpenSlikePlayer, setIsOpenSlikePlayer] = useState(false);
  const [isOpenSlikePlayerView, setIsOpenSlikePlayerView] = useState(false);

  const [isOpenSlikePlayerDigital, setisOpenSlikePlayerDigital] =
    useState(false);

  const [isOpenVideoAd, setIsOpenVideoAd] = React.useState(false);
  const [isArticleViewOpen, setIsArticleViewOpen] = useState(false);
  const [showHeader, setShowHeader] = useState(false);

  const [openTocMenu, setOpenTocMenu] = useState(true);
  const [openTocMenuMob, setOpenTocMenuMob] = useState(true);
  const [pageNextPrevClick, setPageNextPrevClick] = useState(false);
  //loader for mainPage
  const [selectedNewsType, setSelectedNewsType] = useState(
    JSCookie.get("selectnews") ? JSCookie.get("selectnews") : ""
  );

  const [displaySelectionPage, setDisplaySectionPage] = useState(false);
  const [stickyNav, setStickyNav] = useState(false); //state to manage all navbar for mob

  const [mainPageLOader, setMainPageLoader] = useState(false);
  const [ratioMainPage, setRatioMainPage] = useState(1);
  const [fit2page, setFit2page] = useState(false);
  const [fit2pagesArray, setFit2pagesArray] = useState([]);
  ////for article view//
  const [isOpenArticleModal, setIsOpenArticleModal] = useState(false);
  const [isOpenSubscribeAlert, setIsOpenSubscribeAlert] = useState(false);
  const [isOpenExpiredAlert, setIsOpenExpiredAlert] = useState(false);

  const [isOpenThumbZoom, setIsOpenThumbZoom] = useState(false);
  //for digital article view//
  const [isOpenDigitalArticleModal, setIsOpenDigitalArticleModal] =
    useState(false);
  //for search
  const [isOpenSearchArticleModal, setIsOpenSearchArticleModal] =
    useState(false);
  //bpn
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isBPNActive, setisBPNActive] = useState(false);
  //subscribe reminder desktop //
  const [isOpenSubsReminder, setIsOpenSubsReminder] = useState(false);
  const [searchFromAdvanced, setSearchFromAdvanced] = useState(false);
  const [articleViewName, setArticleViewName] = useState(null);
  const [articleViewNameLinked, setArticleViewNameLinked] = useState(null);
  const [articleViewData, setArticleViewData] = useState(null);
  const [relatedArticleData, setRelatedArticleData] = useState(null);
  const [articleViewDataLinked, setArticleViewDataLinked] = useState(null);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpenMobArticle, setIsOpenMobArticle] = useState(false);
  const [isPanoramaPage, setIsPanoramaPage] = useState(false);
  const [isPanoramaPage2, setIsPanoramaPage2] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [currArticleIndex, setCurrArticleIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [views, setViews] = useState(0);
  const [totalShareCount, setTotalShareCount] = useState(0);
  const [articlePubdetails, setArticlePubdetails] = useState({
    editionname: "",
    publicationname: "",
    section: "",
  });
  const [searchPageTitle, setSearchPageTitle] = useState("");
  const [shares, setShares] = useState({
    facebook: 0,
    twitter: 0,
    whatsapp: 0,
    email: 0,
  });
  const [isRegional, setIsRegional] = useState(false);
  const [showButtonView, setShowButtonView] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [activeTabName, setActiveTabName] = useState("article");
  const [linkJsonURL, setLinkJsonURL] = useState("");
  const [hasInfoZone, setHasInfoZone] = useState(false);
  const [isOpenArchive, setIsOpenArchive] = useState(false);
  const [zoom, setZoom] = useState(false);
  const [checkHeight, setCheckHeight] = useState(false);
  //contact
  const [isOpenContactModal, setIsOpenContactModal] = React.useState(false);
  const [isOpenFeedBackModal, setIsOpenFeedBackModal] = React.useState(false);
  const [isOpenTrialInfo, setIsOpenTrialInfo] = React.useState({
    bottom: false,
  });
  const [allowedDates, setAllowedDates] = useState([]);
  const [allowedIntervalArch, setAllowedIntervalArch] = useState({
    intervalStartDate: "",
    intervalEndDate: "",
    defaultStartDate: "",
    defaultEndDate: "",
  });
  function extractDateArch(dateString) {
    const date = dayjs(dateString);
    const extractedDate = date.format("DD MMM YYYY");
    return extractedDate;
  }
  //left publication drawer mob state
  const [state, setState] = React.useState({
    // top: false,
    left: false,
    // bottom: false,
    // right: false,
  });

  const prevScrollPositionRef = React.useRef(null);
  const prevPinchZoomStatusRef = React.useRef(false);
  const [resetScroll, setResetScroll] = React.useState(false);
  const [isPanoramaPageView, setIsPanoramaPageView] = React.useState(false);
  const [isPinchZoom, setIsPinchZoom] = React.useState(false);
  const [showFloatingNav, setShowFloatingNav] = useState(true);
  const [showImgAgency, setShowImgAgency] = useState(true);

  const startNavigate = () => {
    setstepsEnabled(!stepsEnabled);
  };
  const toggle = () => {
    setIsOpen2(!isOpen2);
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const toggleShare = () => setIsOpenMobArticle(!isOpenMobArticle);

  function toggleArticleViewModal() {
    if (isOpenArticleModal) {
      setArticleViewData(null);
      setArticleViewDataLinked(null);
      setHasInfoZone(false);
      setRelatedArticleData([]);
      setGraphsArticle(false);
      setMergedLinkArrays({});
    }

    if (!isOpenArticleModal && selectedNewsType === "e-paper") {
      prevScrollPositionRef.current = window.screenY;
    }
    if (!isOpenArticleModal && selectedNewsType === "digital") {
      document?.body?.classList?.add?.("hideScrollbar");
    } else {
      document?.body?.classList?.remove?.("hideScrollbar");
    }
    if (
      isOpenArticleModal &&
      selectedNewsType !== "digital" &&
      (prevScrollPositionRef.current === null ||
        prevScrollPositionRef.current < 100)
    ) {
      setTimeout(() => {
        prevScrollPositionRef.current = null;
      }, 0);
    }
    setIsOpenArticleModal(!isOpenArticleModal);
    setIsOpenAudioTray(false);
  }

  function ClickAnywhereClose() {
    document.addEventListener("mouseup", function (e) {
      setIsOpenArticleModal(!isOpenArticleModal);
    });
  }

  const calculateNewWidth = useCallback((coord, newWidth, currWidth) => {
    return (coord * newWidth) / currWidth;
  }, []);

  const calculateNewHeight = useCallback(() => {
    return window.innerHeight;
  }, [height]);
  // useEffect(() => {
  //   const BPNStatus = global?.Notification?.permission;

  //   if (BPNStatus === "granted") localStorage.removeItem("bpn_notification");

  //   if (BPNStatus !== "granted" && !localStorage.getItem("bpn_notification")) {
  //     const todaysDate = new Date();
  //     const expiryDateTime = todaysDate.setSeconds(
  //       todaysDate.getSeconds() + 30
  //     );
  //     localStorage.setItem("bpn_notification", new Date(expiryDateTime));
  //   }
  //   if (BPNStatus !== "granted" && localStorage.getItem("bpn_notification")) {
  //     let value = localStorage.getItem("bpn_notification");
  //     if (
  //       new Date(value).getTime() < new Date().getTime() &&
  //       !JSCookie.get("clicked_bpn_sure_mob")
  //     ) {
  //       setIsOpenUpdate(true);
  //     }
  //   }
  //   if (!JSCookie.get("clicked_bpn_sure_mob")) {
  //     setTimeout(() => {
  //       if (
  //         BPNStatus !== "granted" &&
  //         localStorage.getItem("bpn_notification")
  //       ) {
  //         let value = localStorage.getItem("bpn_notification");
  //         if (new Date(value).getTime() < new Date().getTime()) {
  //           setIsOpenUpdate(true);
  //         }
  //       }
  //     }, 30000);
  //   }
  // });
  const updateInsight = async (type, data, share_type) => {
    let name_split = await data?.ArticleName?.split("_");
    let post_url = `${process.env.REACT_APP_SUBSCRIPTION_URL}/epaper/insight`;
    if (name_split && name_split.length > 0) {
      let data_object = {
        type,
        articlename: data?.ArticleName,
        articletitle: data?.ArticleTitle,
        edition_code: name_split?.[5], //edition_code,
        edition_date: `${name_split?.[2]}-${name_split?.[1]}-${name_split?.[0]}`, //edition_date,
      };
      if (type === "share") {
        data_object.share_type = share_type;
      }
      axios
        .post(post_url, data_object, {
          headers: {
            token: JSCookie.get("andre"),
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (type === "view") {
            setViews(res.data.view_count);
          } else {
            let shareCount = {
              ...shares,
              [share_type]: res.data.share_count[share_type],
            };
            setShares(shareCount);
            let totalShareCount =
              shareCount["email"] ??
              0 + shareCount["facebook"] ??
              0 + shareCount["whatsapp"] ??
              0 + shareCount["twitter"] ??
              0;
            setTotalShareCount(totalShareCount ?? 0);
          }
        })
        .catch((err) => {
          console.log("updateInsight err:", err);
        });
    }
  };
  const refactorHighlight = useCallback(
    (newWidth, currWidth) => {
      setArticles((currentState) => {
        const newArticles = currentState?.PageContent?.map((article) => {
          return {
            ...article,
            Zones: article.Zones.map((zone) => {
              return {
                ...zone,
                Y: calculateNewWidth(zone.Y, newWidth, currWidth),
                X: calculateNewWidth(zone.X, newWidth, currWidth),
                H: calculateNewWidth(zone.H, newWidth, currWidth),
                W: calculateNewWidth(zone.W, newWidth, currWidth),
              };
            }),
          };
        });
        return { ...currentState, PageContent: newArticles };
      });
    },
    [articles, setArticles, calculateNewWidth]
  );

  const refactorHighlightPage2 = useCallback(
    (newWidth, currWidth) => {
      setArticlesPage2((currentState) => {
        const newArticles = currentState?.PageContent?.map((article) => {
          return {
            ...article,
            Zones: article.Zones.map((zone) => {
              return {
                ...zone,
                Y: calculateNewWidth(zone.Y, newWidth, currWidth),
                X: calculateNewWidth(zone.X, newWidth, currWidth),
                H: calculateNewWidth(zone.H, newWidth, currWidth),
                W: calculateNewWidth(zone.W, newWidth, currWidth),
              };
            }),
          };
        });
        return { ...currentState, PageContent: newArticles };
      });
    },
    [articlesPage2, setArticlesPage2, calculateNewWidth]
  );

  const vw = useCallback((w) => {
    return (w * window.innerWidth) / 100;
  }, []);

  const vh = useCallback((h) => {
    return (h * window.innerHeight) / 100;
  }, []);

  // import from app.js
  const [loading, setLoading] = useState(false);
  const [publicationsData, setPublicationsData] = useState([]);
  // const [isOpenAppIntro, setIsOpenAppIntro] = useState(
  //   JSCookie.get("userCheckIntro")
  //     ? false
  //     : JSCookie.get("selectionHp")
  //     ? false
  //     : JSCookie.get("pubindex") !== "0"
  //     ? false
  //     : true
  // );
  const [isOpenAppIntro, setIsOpenAppIntro] = useState(false);
  const [selectSection, setSelectSection] = useState("");
  const [digiDayIndexData, setDigiDayIndexData] = useState([]);
  const [digitalDayIndex, setDigitalDayIndex] = useState([]);
  const [moreFromArticles, setMoreFromArticles] = useState([]);
  const [articleTextView, setArticleTextView] = useState(true);

  const [moreFromSection, setMoreFromSection] = useState([]);
  const [editionData, setEditionsData] = useState([]);
  const [initialPublication, setInitialPublication] = useState({});
  const [initialEdition, setInitialEdition] = useState({});
  const [todaysDate, setTodaysDate] = useState(null);
  const [dateArray, setDateArray] = useState([]);
  const [dateIndexData, setDateIndexData] = useState([]);
  const [dateIndexDataArchive, setDateIndexDataArchive] = useState([]);
  const [openSlideCard, setOpenSlideCard] = useState(false);

  const [dayIndexData, setDayIndexData] = useState([]);
  const [sectionData, setSectionData] = useState([]);
  const [currentPage, setCurrentPage] = useState({});
  const [nextPage, setNextPage] = useState({});
  const [displayArticle, setDisplayArticle] = useState("");
  const [activeTabMenu, setActiveTabMenu] = useState("epaper");
  const [isIntroStart, setIsIntroStart] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  const [sidebar, setSidebar] = useState(false);

  // const [logo, setLogo] = useState(null);
  // const [isLandingPage, setIsLandingPage] = useState(true);
  const [items, setItems] = useState({});
  const [isSearch, setIsSearch] = useState(false);
  const [showPageThumb, setShowPageThumb] = useState(false);

  const [currentIndexHome, setCurrentIndexHome] = useState(0);

  const [currentPublication, setCurrentPublication] = useState({});
  // const [fit2page, setFit2page] = useState(false);
  const [isSecondPage, setIsSecondPage] = useState(false);
  const [contentModalURL, setContentModalURL] = useState("");
  const [logo, setLogo] = useState();
  const [themeColor, setThemeColor] = useState();
  const [propsArticles, setPropsArticles] = useState([]);
  const [favIcon, setFavicon] = useState();
  const [mostViewed, setMostViewed] = useState([]);
  const [mostShared, setMostShared] = useState([]);
  const [filteredDate, setFilteredDate] = useState({});
  const [linkJsonData, setLinkJsonData] = useState({});
  const [newLinkArticles, setNewLinkArticles] = useState([]);
  const [articleLoading, setArticleLoading] = useState(false);
  const [bookmarks, setBookmarks] = useState([]);
  const [articleNameSlikeView, setArticleNameSlikeView] = useState(""); //for digital view
  const [iosGraphData, setIosGraphData] = useState({
    //for digital view ios
    graph: "",
    articleName: "",
    ZoneText: "",
  });
  const getPublicationsData = async (take_first, pageName) => {
    try {
      setDateArray([]);
      setInitialPublication({});
      setInitialEdition({});
      setEditionsData([]);
      setCurrentPage({});
      setNextPage({});

      var pubIndex = 0,
        edIndex = 12;
      let user_preference = JSCookie.get("user_preference");
      if (!JSCookie.get("pubindex") && user_preference) {
        pubIndex = user_preference.split("_")[0];
        await JSCookie.set("pubindex", pubIndex, { expires: 3 });

        edIndex = user_preference.split("_")[1];
        await JSCookie.set("edindex", edIndex, { expires: 3 });
      }

      // key-value
      if (JSCookie.get("pubindex")) {
        pubIndex = JSCookie.get("pubindex");
      } else {
        JSCookie.set("pubindex", pubIndex, { expires: 3 });
      }
      if (JSCookie.get("edindex")) {
        edIndex = JSCookie.get("edindex");
      } else {
        JSCookie.set("edindex", edIndex, { expires: 3 });
      }
      let tempInitialPub = "";
      let tempInitialEdition = "";

      // const favicon = document.getElementById("favicon");

      tempInitialPub = publicationJson.Publications[Number(pubIndex)];
      await setInitialPublication({
        ...tempInitialPub,
        index: Number(pubIndex),
      });
      await setCurrentPublication({
        ...tempInitialPub,
        index: Number(pubIndex),
      });

      tempInitialEdition =
        publicationJson.Publications[Number(pubIndex)].editions[
          Number(edIndex)
        ];
      await setInitialEdition({
        ...tempInitialEdition,
        index: Number(edIndex),
      });

      await setPublicationsData(publicationJson.Publications); // array of publications.

      await setEditionsData(
        publicationJson.Publications[Number(pubIndex)].editions
      ); // array of editions of the await set publication
      document.title =
        publicationJson.Publications[Number(pubIndex)]?.publicationname +
        " | e-Edition";
      await setThemeColor(
        publicationJson.Publications[Number(pubIndex)]?.theme_color
      );
      await setLogo(tempInitialPub.logo);
      // favicon.setAttribute("href", tempInitialPub.favicon);

      setRootStyles(tempInitialPub);
      await getDates(
        tempInitialPub,
        tempInitialEdition,
        Number(pubIndex),
        Number(edIndex),
        take_first,
        pageName
      );

      // });
    } catch (error) {
      setLoading(false);
      console.log("error: ", error);
    }
  };
  // useEffect(() => {
  //   setPropsArticles(items?.Articles);
  //   console.log("items?.Articles: ", items?.Articles);
  // }, [items]);

  const setRootStyles = (tempInitialPub) => {
    let r = document.querySelector(":root");
    r.style.setProperty("--primary", tempInitialPub.theme_color);
    //setting font
    r.style.setProperty("--font", tempInitialPub.font.family);
    r.style.setProperty(
      "--articletitlefontsize",
      tempInitialPub.font.article.title.fontsize
    );
    r.style.setProperty(
      "--articletitlelineheight",
      tempInitialPub.font.article.title.lineheight
    );
    r.style.setProperty(
      "--articlebodyfontsize",
      tempInitialPub.font.article.content.fontsize
    );
    r.style.setProperty(
      "--articlebodylineheight",
      tempInitialPub.font.article.content.lineheight
    );
    r.style.setProperty(
      "--relatedtitlefontsize",
      tempInitialPub.font.related.title.fontsize
    );
    r.style.setProperty(
      "--relatedtitlelineheight",
      tempInitialPub.font.related.title.lineheight
    );
    r.style.setProperty(
      "--relatedbodyfontsize",
      tempInitialPub.font.related.content.fontsize
    );
    r.style.setProperty(
      "--relatedbodylineheight",
      tempInitialPub.font.related.content.lineheight
    );
  };

  useEffect(() => {
    if (localStorage.getItem("contentRec"))
      setIsEnableAds(
        localStorage.getItem("contentRec") === "true" ? "Enable" : "Disable"
      );

    if (localStorage.getItem("webAnalytics"))
      setIsEnableAnalytics(
        localStorage.getItem("webAnalytics") === "true" ? "Enable" : "Disable"
      );
  }, []);
  useEffect(() => {
    if (dateArray?.length > 0) fetchBookmark();
  }, [dateArray]);

  useEffect(() => {
    if (window.location.href.includes("#digital-")) {
      sectionDateRef.current = true;
    } else {
      sectionDateRef.current = false;
    }
  }, []);

  useEffect(() => {
    //for view page in article card
    if (
      window.location.href.includes("viewPage") &&
      JSCookie.get("viewPageTrue") === "true"
    ) {
      viewOnPageRef.current = true;
      JSCookie.set("selectnews", "e-paper", { expires: 365 });
      setSelectedNewsType("e-paper");
      setLastVisited(initialPublication, initialEdition, "e-paper");
      JSCookie.set("viewPageTrue", false, { expires: 365 });
    } else {
      viewOnPageRef.current = false;
    }
  }, []);
  const sectionDateRef = useRef(false);
  const viewOnPageRef = useRef(false);

  const getDates = async (
    tempInitialPub,
    tempInitialEdition,
    pubIndex,
    edIndex,
    take_first,
    pageName
  ) => {
    const url = `${
      publicationJson[process.env.REACT_APP_ENV]
    }/PublicationData/${tempInitialPub.publicationcode}/${
      tempInitialEdition.editionid
    }/${dateKeyJsonFilename}.json`;
    await axios
      .get(url)
      .then(async (res) => {
        //to get latest date first
        let newDateIndex = [];

        if (process.env.REACT_APP_ENV !== "PROD") {
          for (let i = 0; i < res.data.DateIndex.length; i++) {
            const ele = res.data.DateIndex[i];
            newDateIndex.push({
              Date: ele,
              Timestamp: new Date().getTime(),
            });
          }
        } else {
          if (res.data && res.data.length > 0) {
            newDateIndex = [...res.data];
          }
        }
        //sort to get latest date first
        let dateIndexSorted = await newDateIndex.sort((a, b) =>
          new Date(a.Date.split("-").reverse().join("-")) <
          new Date(b.Date.split("-").reverse().join("-"))
            ? 1
            : -1
        );

        if (
          JSCookie.get("fromArchive") &&
          JSCookie.get("eddate") &&
          dateIndexSorted?.filter((v) => v.Date === JSCookie.get("eddate"))
            ?.length == 0
        ) {
          dateIndexSorted.push({
            Date: JSCookie.get("eddate"),
            Timestamp: new Date().getTime(),
          });
          dateIndexSorted = await dateIndexSorted.sort((a, b) =>
            new Date(a.Date.split("-").reverse().join("-")) <
            new Date(b.Date.split("-").reverse().join("-"))
              ? 1
              : -1
          );
        }

        if (
          viewOnPageRef.current &&
          dateIndexSorted?.filter((v) => v.Date === JSCookie.get("eddate"))
            ?.length == 0
        ) {
          const viewRegex = /viewPage=(\d{2}-\d{2}-\d{4})/;
          dateIndexSorted.push({
            Date: window.location.href.match(viewRegex)[1],
            Timestamp: new Date().getTime(),
          });
          dateIndexSorted = await dateIndexSorted.sort((a, b) =>
            new Date(a.Date.split("-").reverse().join("-")) <
            new Date(b.Date.split("-").reverse().join("-"))
              ? 1
              : -1
          );
        }
        if (window.location.pathname.includes("/print/")) {
          let ddmmyyyy = window.location.pathname
            .split("/")[4]
            ?.split("-")
            .join(" ")
            .split("&")?.[0]
            .split("");

          let final_date = `${ddmmyyyy.slice(0, 2).join("")}-${ddmmyyyy
            .slice(2, 4)
            .join("")}-${ddmmyyyy.slice(-4).join("")}`;

          dateIndexSorted.push({
            Date: final_date,
            Timestamp: new Date().getTime(),
          });

          dateIndexSorted = await dateIndexSorted.sort((a, b) =>
            new Date(a.Date.split("-").reverse().join("-")) <
            new Date(b.Date.split("-").reverse().join("-"))
              ? 1
              : -1
          );
        } else if (take_first) {
          await JSCookie.set("eddate", dateIndexSorted[0]?.Date);
        }

        await setDateIndexData(
          dateIndexDataArchive?.length > 0
            ? dateIndexDataArchive
            : dateIndexSorted
        );
        if (sectionDateRef.current) {
          const regex = /\d{2}-\d{2}-\d{4}/;
          let extractDate = window.location.hash.match(regex);
          let date = extractDate[0]?.split("-").reverse();
          setTodaysDate(extractDate[0]);
          await setDateArray(date);
        } else if (viewOnPageRef.current) {
          const regex = /viewPage=(\d{2}-\d{2}-\d{4})/;
          const match = window.location.href.match(regex);
          const extractDate = match[1];
          let date = extractDate?.split("-").reverse();
          setTodaysDate(match[1]);
          await setDateArray(date);
        } else if (
          JSCookie.get("eddate") &&
          JSCookie.get("pubindex") == pubIndex &&
          JSCookie.get("edindex") == edIndex
        ) {
          let today = JSCookie.get("eddate");
          await setTodaysDate(today);
          let date = JSCookie.get("eddate").split("-").reverse();
          await setDateArray(date);
        } else {
          let today = dateIndexSorted[0]?.Date;

          await setTodaysDate(today);
          // JSCookie.set("eddate", today);
          let date = dateIndexSorted[0].Date?.split("-").reverse();

          await setDateArray(date);
        }

        const regex = /\d{2}-\d{2}-\d{4}/;
        const viewPageregex = /viewPage=(\d{2}-\d{2}-\d{4})/;
        let matchViewPageDate = window.location.href.match(viewPageregex);
        await getDatesApiData(
          tempInitialPub,
          tempInitialEdition,
          dateIndexDataArchive?.length > 0
            ? dateIndexDataArchive.filter(
                (v) => v.Date === JSCookie.get("eddate")
              )?.[0]
            : sectionDateRef.current
            ? dateIndexSorted.filter(
                (v) => v.Date === window.location.hash.match(regex)[0]
              )?.[0]
            : viewOnPageRef.current
            ? dateIndexSorted.filter(
                (v) => v.Date === matchViewPageDate[1]
              )?.[0]
            : dateIndexSorted.filter(
                (v) => v.Date === JSCookie.get("eddate")
              )?.[0],
          pageName
        );
      })
      .catch((err) => {
        console.log("getDates err: ", err);
        setLoading(false);
      });
  };
  const getDatesApiData = async (
    tempInitialPub,
    tempInitialEdition,
    date,
    pageName = viewOnPageRef.current ? JSCookie.get("pageName") : pageName
  ) => {
    console.log("date: ", date);

    if (date) {
      await getLinkJson(tempInitialPub, tempInitialEdition, date);
      await getInsights(tempInitialEdition);
      let url = `${
        publicationJson[process.env.REACT_APP_ENV]
      }/PublicationData/${tempInitialPub.publicationcode}/${
        tempInitialEdition.editionid
      }/${date?.Date.split("-")
        .reverse()
        .join("/")}/DayIndex/${date?.Date.split("-").join("_")}_${
        tempInitialEdition.editionid
      }.json?v=${date?.Timestamp ?? Date.now()}`;

      await axios
        .get(url)
        .then(async (dayIndexRes) => {
          await setDayIndexData(dayIndexRes.data.DayIndex);
          let pageTitleKey = {};

          if (pageName) {
            let filteredPage = dayIndexRes.data.DayIndex.findIndex(
              (v) => v.PageName === pageName
            );
            if (filteredPage !== -1) {
              await setCurrentPage({
                ...dayIndexRes.data.DayIndex[filteredPage],
                pageNumber: filteredPage + 1,
              });
              await setNextPage({
                ...dayIndexRes.data.DayIndex[filteredPage + 1],
                pageNumber: filteredPage + 2,
              });
            } else {
              await setCurrentPage({
                ...dayIndexRes.data.DayIndex[0],
                pageNumber: 1,
              });
              await setNextPage({
                ...dayIndexRes.data.DayIndex[1],
                pageNumber: 2,
              });
            }
          } else {
            await setNextPage({
              ...dayIndexRes.data.DayIndex[1],
              pageNumber: 2,
            });
            await setCurrentPage({
              ...dayIndexRes.data.DayIndex[0],
              pageNumber: 1,
            });
          }
          dayIndexRes.data.DayIndex.forEach((singlePage) => {
            if (!pageTitleKey[singlePage.PageTitle]) {
              pageTitleKey[singlePage.PageTitle] = [];
            }
            if (singlePage.Articles) {
              singlePage.Articles.forEach((singleArticle) => {
                pageTitleKey[singlePage.PageTitle].push({
                  title: singleArticle?.ArticleTitle?.replace(
                    /(<([^>]+)>)/gi,
                    ""
                  ),
                  name: singleArticle.ArticleName,
                  pagename: singlePage.PageName,
                });
              });
            }
          });
          await setItems(dayIndexRes?.data?.DayIndex?.[0]);

          await setPropsArticles(dayIndexRes?.data?.DayIndex?.[0]?.Articles);
          setSectionData(pageTitleKey);

          if (
            dayIndexRes?.data?.DigitalIndex &&
            dayIndexRes?.data?.DigitalIndex?.length > 0
          ) {
            let result = dayIndexRes.data?.DigitalIndex?.filter(
              (v) => v.active === true
            );
            let data = [];

            for (let i = 0; i < result.length; i++) {
              const section = result[i];

              let articles = [];
              for (let s = 0; s < section.Views.length; s++) {
                const view = section.Views[s];

                articles = [...articles, ...view.Articles];
              }
              let obj = { ...section, Articles: articles };
              delete obj.Views;
              data.push(obj);
            }
            setMoreFromArticles(data);
            setDigitalDayIndex(result);
          }
        })
        .catch((err) => {
          console.log("getDatesApiData err: ", err);
          setLoading(false);
        });
    }
  };

  const getDigitalDayIndex = async () => {
    if (dateArray?.length > 0) {
      let url = `${
        publicationJson[process.env.REACT_APP_ENV]
      }/PublicationData/${initialPublication.publicationcode}/${
        initialEdition.editionid
      }/${dateArray.join("/")}/DayIndex/digitalIndex.json?v=${Date.now()}`;
      await axios
        .get(url)
        .then(async (dayIndexRes) => {
          let result = dayIndexRes.data?.DigitalIndex?.filter(
            (v) => v.active === true
          );
          let data = [];

          for (let i = 0; i < result.length; i++) {
            const section = result[i];

            let articles = [];
            for (let s = 0; s < section.Views.length; s++) {
              const view = section.Views[s];

              articles = [...articles, ...view.Articles];
            }
            let obj = { ...section, Articles: articles };
            delete obj.Views;
            data.push(obj);
          }
          setMoreFromArticles(data);
          setDigitalDayIndex(result);
        })
        .catch((err) => {
          console.log("getDatesApiData err: ", err);
          setLoading(false);
          setDigitalDayIndex([]);
        });
    }
  };

  useEffect(() => {
    if (selectedNewsType === "digital") {
      // getDigitalDayIndex();
    }
  }, [dateArray, initialEdition, initialPublication, selectedNewsType]);

  const getRelatedArticles = async (name, index) => {
    //get related articles;
    let n = name.split("/");
    let filename = n.pop();
    let fchunks = filename.split("_");
    fchunks.pop();
    if (fchunks.length > 3) fchunks.pop();
    let url = n.join("/") + "/" + fchunks.join("_") + "_" + n[5] + ".json";
    url = url.replace("PageThumb", "DayIndex");
    await axios
      .get(url)
      .then(async (response) => {
        await setItems(response?.data?.DayIndex?.[index]);

        await setPropsArticles(response?.data?.DayIndex?.[index]?.Articles);
      })
      .catch((err) => {
        console.log("getRelatedArticles err", err);
        setItems({});
        setLoading(false);
      });
  };
  useEffect(() => {
    // let exclude = ["/login"];
    // if (!JSCookie.get("isLanding")) {
    //   JSCookie.set("isLanding", false);
    // }
    // if (
    //   !exclude.includes(window.location.pathname) &&
    //   JSCookie.get("isLanding") === "false"
    // ) {
    const callFunctions = async () => {
      await updateCookies();
      // await getPublicationsData(true);
    };
    callFunctions();
    // }
  }, [window?.location?.pathname]);

  //function to send user las pub and edition info
  const userPubEdData = (publication, edition) => {
    let url = `${process.env.REACT_APP_PAYWALL_URL}/savePubEdition.html?publication=${publication?.publicationname}&edition=${edition?.editionname}&customerid=${userInfo?.ref_id}`;

    axios
      .get(url)
      .then((response) => {
        console.log("re", response?.data);
      })
      .catch((error) => {
        console.error("Error--:", error);
      });
  };
  console.log("prodd");
  const handlePublication = async (publication, index) => {
    setLoading(true);
    setCurrentPublication(publication);
    const favicon = document.getElementById("favicon");

    await setFavicon(initialPublication.favicon);
    await JSCookie.set("pubindex", index, { expires: 3 });
    await JSCookie.set("edindex", 0, { expires: 3 });
    await getPublicationsData(true);
  };

  const setURL = async () => {
    await JSCookie.set("pubindex", 0, { expires: 3 });
    await JSCookie.set("edindex", 12, { expires: 3 });
    window.location.assign(
      `${
        window.location.origin
      }/${publicationJson.Publications[0].publicationname
        .toLowerCase()
        .split(" ")
        .join("-")}/${publicationJson.Publications[0].editions[12].editionname
        .toLowerCase()
        .split(" ")
        .join("-")}`
    );
  };
  const updateCookies = async () => {
    let pub = window.location.pathname.split("/")[1]?.split("-").join(" ");
    let edn = window.location.pathname
      .split("/")[2]
      ?.split("-")
      .join(" ")
      .split("&")?.[0];
    if (pub === "undefined" || edn === "undefined") {
      setURL();
    }
    const exclude = ["/digest-view", "/home", "/gallery-view", "my-account"];
    if (pub) {
      let pub_filter = publicationJson.Publications.findIndex(
        (v) => v.publicationname.toLowerCase() === pub
      );
      if (pub_filter === -1) {
        if (window.location.pathname.includes("/print/")) {
          JSCookie.set("selectnews", "e-paper", {
            expires: 365,
          });
          let filtered_pub = publicationJson.Publications.findIndex(
            (v) =>
              v.publicationname.toLowerCase() ===
              window.location.pathname.split("/")[2]?.split("-").join(" ")
          );
          if (filtered_pub !== -1) {
            JSCookie.set("pubindex", filtered_pub, { expires: 3 });

            let filtered_edn = publicationJson.Publications[
              filtered_pub
            ].editions.findIndex(
              (x) =>
                x.editionname.toLowerCase() ==
                window.location.pathname
                  .split("/")[3]
                  ?.split("-")
                  .join(" ")
                  .split("&")?.[0]
            );

            if (filtered_edn !== -1) {
              JSCookie.set("edindex", filtered_edn, { expires: 3 });
              let ddmmyyyy = window.location.pathname
                .split("/")[4]
                ?.split("-")
                .join(" ")
                .split("&")?.[0]
                .split("");

              let final_date = `${ddmmyyyy.slice(0, 2).join("")}-${ddmmyyyy
                .slice(2, 4)
                .join("")}-${ddmmyyyy.slice(-4).join("")}`;
              // handle junk chars in date
              if (
                !ddmmyyyy.join("").match(/^\d+$/) ||
                ddmmyyyy.join("").length != 8
              ) {
                window.location.assign(`${window.location.origin}/home`);
                return;
              }

              const dateindex_url = `${
                publicationJson[process.env.REACT_APP_ENV]
              }/PublicationData/${
                publicationJson.Publications[filtered_pub]?.publicationcode
              }/${
                publicationJson.Publications[filtered_pub]?.editions[
                  filtered_edn
                ]?.editionid
              }/${final_date
                .split("-")
                .reverse()
                .join("/")}/DayIndex/${final_date.split("-").join("_")}_${
                publicationJson.Publications[filtered_pub]?.editions[
                  filtered_edn
                ]?.editionid
              }.json`;

              await axios
                .get(dateindex_url)
                .then(async (res) => {
                  JSCookie.set("eddate", final_date);
                  let array = [];
                  array.push({
                    pub: publicationJson.Publications[filtered_pub]
                      ?.publicationcode,
                    visits: [
                      {
                        edn: publicationJson.Publications[filtered_pub]
                          ?.editions[filtered_edn]?.editionname,
                        view: "e-paper",
                      },
                    ],
                  });

                  localStorage.setItem("userPref", JSON.stringify(array));

                  setLastVisited(
                    filtered_pub?.[0],
                    filtered_edn?.[0],
                    "e-paper"
                  );
                })
                .catch((err) => {
                  console.log("day index data not found: ", err);
                  // if date not present in bucket then, set current date
                  final_date = moment().format("DD-MM-YYYY");
                  JSCookie.set("eddate", final_date);
                  let array = [];
                  array.push({
                    pub: publicationJson.Publications[filtered_pub]
                      ?.publicationcode,
                    visits: [
                      {
                        edn: publicationJson.Publications[filtered_pub]
                          ?.editions[filtered_edn]?.editionname,
                        view: "e-paper",
                      },
                    ],
                  });

                  localStorage.setItem("userPref", JSON.stringify(array));

                  setLastVisited(
                    filtered_pub?.[0],
                    filtered_edn?.[0],
                    "e-paper"
                  );
                });

              return;
            } else {
              window.location.assign(`${window.location.origin}/home`);
            }
          } else {
            window.location.assign(`${window.location.origin}/home`);
          }
        } else {
          if (window.location.pathname.split("/").length > 2) {
            window.location.assign(`${window.location.origin}/home`);
          }
        }
        // if (exclude.includes(pub)) {
        //   console.log("!exclude.includes(pub): ", !exclude.includes(pub));
        // } else {
        //   setURL();
        // }
      }
      if (edn && pub_filter !== -1) {
        let edn_filter = publicationJson.Publications[
          pub_filter
        ].editions.findIndex((v) => v.editionname.toLowerCase() === edn);
        JSCookie.set("pubindex", pub_filter, { expires: 3 });

        if (edn_filter !== -1) {
          JSCookie.set("edindex", edn_filter, { expires: 3 });

          if (localStorage.getItem("userPref")) {
            let array = JSON.parse(localStorage.getItem("userPref"));

            let indexOfPub = array.findIndex(
              (v) =>
                v.pub ===
                publicationJson.Publications[pub_filter]?.publicationcode
            );

            if (indexOfPub !== -1) {
              let ednIndex = array[indexOfPub].visits?.findIndex(
                (x) =>
                  x.edn ==
                  publicationJson.Publications[pub_filter].editions[edn_filter]
                    .editionname
              );

              if (ednIndex !== -1) {
                console.log(
                  "calling setLastVisited",
                  array[indexOfPub].visits[ednIndex]?.view
                );
                setLastVisited(
                  publicationJson.Publications[pub_filter],
                  publicationJson.Publications[pub_filter].editions[edn_filter],
                  array[indexOfPub].visits[ednIndex].view
                );
              } else {
                console.log("calling setLastVisited", selectedNewsType);
                setLastVisited(
                  publicationJson.Publications[pub_filter],
                  publicationJson.Publications[pub_filter].editions[edn_filter]
                );
              }
            } else {
              console.log("calling setLastVisited", selectedNewsType);
              setLastVisited(
                publicationJson.Publications[pub_filter],
                publicationJson.Publications[pub_filter].editions[edn_filter]
              );
            }
          } else {
            setLastVisited(
              publicationJson.Publications[pub_filter],
              publicationJson.Publications[pub_filter].editions[edn_filter]
            );
          }
        } else {
          JSCookie.set("edindex", 0, { expires: 3 });
          console.log("calling setLastVisited");
          setLastVisited(
            publicationJson.Publications[pub_filter],
            publicationJson.Publications[pub_filter].editions[0]
          );
          window.location.assign(
            `${window.location.origin}/${publicationJson.Publications[
              pub_filter
            ].publicationname
              .toLowerCase()
              .split(" ")
              .join("-")}/${publicationJson.Publications[
              pub_filter
            ].editions[0].editionname
              .toLowerCase()
              .split(" ")
              .join("-")}`
          );
        }
      }
    }
  };
  const getIP = async () => {
    const IP = await publicIp.v4();
    await fetch(
      `https://pro.ip-api.com/json/${IP}?fields=country,continentCode,city,zip,regionName,query&key=2tjq7YXrdexnO8N`
    ).then(async (res) => {
      const data = await res.json();
      setIPData(data);
      localStorage.setItem("continentCode", data.continentCode);
      if (data.continentCode !== "EU") {
        localStorage.setItem("webAnalytics", true);
        localStorage.setItem("contentRec", true);
      }
    });
  };

  function findAllArticleDetails(articleId) {
    let articleName = articleId?.split("_");
    let editionNew = articleName?.[articleName?.length - 1];
    // Find the publication with the given edition ID
    const targetPublication = publicationJson.Publications.find((publication) =>
      publication.editions.some((edition) => edition.editionid === editionNew)
    );

    // Extract editionname and publicationname if the targetPublication is found
    let editionname, publicationname;

    if (targetPublication) {
      const targetEdition = targetPublication.editions.find(
        (edition) => edition.editionid === editionNew
      );
      if (targetEdition) {
        editionname = targetEdition.editionname;
        publicationname = targetPublication.publicationname;
        setArticlePubdetails({
          editionname: targetEdition.editionname,
          publicationname: targetPublication.publicationname,
        });
      }
    }
    return;
  }

  const print = (elementId) => {
    const printContent = document.getElementById(elementId);

    // Create a hidden iframe for printing
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    document.body.appendChild(iframe);

    // Create a document within the iframe
    const iframeDocument = iframe.contentDocument;
    const iframeWindow = iframe.contentWindow;

    // Create a string with your custom text
    const customText = `<div style="font-size:10px; font-style:italic " class="print_article">${
      articlePubdetails?.publicationname?.length > 0
        ? articlePubdetails?.publicationname
        : initialPublication?.publicationname
    }-${
      articlePubdetails?.editionname?.length > 0
        ? articlePubdetails?.editionname
        : initialEdition?.editionname
    } ${downloadPrint ? "" : `Section:`}  ${
      downloadPrint
        ? ""
        : searchPageTitle?.length > 0
        ? searchPageTitle
        : currentPage?.PageTitle
    } Date:${
      articleViewData?.ArticleName?.match(/\d{2}_\d{2}_\d{4}/)[0]
        .split("_")
        .join("-") ??
      currentPage?.PageName?.match(/\d{2}_\d{2}_\d{4}/)[0]
        .split("_")
        .join("-")
    } </div>`;
    const articleAuthor = `<div style="font-size:10px; font-style:italic " class="print_article">By: ${articleViewData?.Author} </div>`;

    // Add the custom text before the content of the element
    const contentToPrint =
      selectedNewsType === "e-paper" && articleViewData?.Author?.length > 0
        ? customText + articleAuthor + printContent?.innerHTML
        : selectedNewsType === "e-paper"
        ? customText + printContent?.innerHTML
        : printContent?.innerHTML;

    // Write the content to the iframe document
    iframeDocument.open();
    iframeDocument.write(contentToPrint);
    iframeDocument.close();

    // Add CSS rules to the iframe document
    const style = iframeDocument.createElement("style");
    style.innerHTML = `
      img {
        max-height: 600px;
        width: auto !important;
        max-width: 1000px;
      }
      .slick-slider{
        display: none;
      }
    `;
    iframeDocument.head.appendChild(style);

    // Focus and print the iframe content
    iframeWindow.focus();
    iframeWindow.print();

    // Remove the iframe after printing
    setTimeout(() => {
      document.body.removeChild(iframe);
    }, 1000);
  };

  useEffect(() => {
    if (!localStorage.getItem("continentCode")) {
      getIP();
    } else {
      if (localStorage.getItem("continentCode") !== "EU") {
        localStorage.setItem("webAnalytics", true);
        localStorage.setItem("contentRec", true);
      }
    }
  }, []);
  const handleEdition = async (edition, index) => {
    setLoading(true);
    await JSCookie.set("edindex", index, { expires: 3 });
    await getPublicationsData(true);
  };

  // useEffect(() => {
  const getInsights = async (tempInitialEdition) => {
    let date = JSCookie.get("eddate")?.split("-");
    if (date && date.length > 0 && tempInitialEdition?.editionid) {
      axios
        .get(
          `${process.env.REACT_APP_SUBSCRIPTION_URL}/epaper/insight/${tempInitialEdition?.editionid}/${date[2]}-${date[1]}-${date[0]}`
        )
        .then((res) => {
          setMostViewed(res.data.most_viewed);
          setMostShared(res.data.most_shared);
        })
        .catch((err) => {
          console.log("err: ", err);
        });
    }
  };

  // let exclude = ["/home", "/login"];
  // if (!exclude.includes(window.location.pathname)) {
  //   getInsights();
  // } else {
  // }
  // }, [
  //   initialEdition,
  //   initialPublication,
  //   dateIndexData,
  //   dateArray,
  //   dayIndexData,
  // ]);

  const handleDate = async (date) => {
    setLoading(true);
    sectionDateRef.current = false;
    viewOnPageRef.current = false;
    await JSCookie.set("eddate", date);
    getPublicationsData();
  };

  const setLogoAndTheme = (theme_color, logo) => {
    setLogo(logo);
    setThemeColor(theme_color);
  };

  useEffect(() => {
    let filteredDayIndexData = dayIndexData?.filter(function (
      value,
      index,
      arr
    ) {
      return value?.PageTitle !== "Advertisement";
    });
    setDigiDayIndexData(filteredDayIndexData);
  }, [dayIndexData]);

  useEffect(() => {
    try {
      if (dayIndexData && dayIndexData.length > 0) {
        const groupedIndexArray = [];
        const groupedIndex = {};
        let flapArticles = [];

        dayIndexData?.forEach((item, i) => {
          if (item?.PageTitle !== "Edit Flap") {
            groupedIndex[item?.PageTitle] = {
              Articles: [
                ...(groupedIndex[item?.PageTitle]?.Articles ?? []),
                ...(item?.Articles ?? []),
              ],
              DisplayPageNumber: item?.DisplayPageNumber,
              PageName: item?.PageName,
              PageTitle: item?.PageTitle,
              SortOrder: item?.SortOrder,
            };
          } else {
            let currentFlapArticles = item?.Articles?.map((item) => {
              return {
                ...item,
                isFlap: true,
              };
            });

            flapArticles = [...flapArticles, ...currentFlapArticles];
            // const shuffle = [
            //   ...(flapArticles ?? []),
            //   ...(groupedIndex["Front Page"]?.Articles ?? []),
            // ];

            // groupedIndex["Front Page"] = {
            //   Articles: shuffle,
            //   DisplayPageNumber: item?.DisplayPageNumber,
            //   PageName: item?.PageName,
            //   PageTitle: item?.PageTitle,
            //   SortOrder: item?.SortOrder,
            // };
          }
        });

        let finalFrontPageArray = [];

        flapArticles = flapArticles?.reverse();

        let frontPageArticles = [
          ...(groupedIndex["Front Page"]?.Articles ?? []),
        ];
        const frontPageArtLength =
          flapArticles?.length + frontPageArticles?.length;
        frontPageArticles = frontPageArticles.reverse();

        if (Number.isInteger(frontPageArtLength) && flapArticles?.length > 0) {
          for (let i = 0; i <= frontPageArtLength - 1; i++) {
            if (flapArticles?.length === 0) {
              finalFrontPageArray[i] = frontPageArticles?.pop();
            } else if (frontPageArticles.length === 0) {
              finalFrontPageArray[i] = flapArticles?.pop();
            } else {
              if (i % 2 === 0) {
                finalFrontPageArray[i] = frontPageArticles?.pop();
              } else {
                finalFrontPageArray[i] = flapArticles?.pop();
              }
            }
          }
          groupedIndex["Front Page"] = {
            ...(groupedIndex["Front Page"] ?? []),
            Articles: finalFrontPageArray,
          };
        }

        initialEdition?.tocDigital?.forEach((item, i) => {
          if (groupedIndex[item])
            groupedIndexArray.push({
              Articles: groupedIndex[item]?.Articles,
              DisplayPageNumber: groupedIndex[item]?.DisplayPageNumber,
              PageName: groupedIndex[item]?.PageName,
              PageTitle: groupedIndex[item]?.PageTitle,
              SortOrder: groupedIndex[item]?.SortOrder,
              PageTitle: groupedIndex[item]?.PageTitle,
            });
        });

        if (digitalDayIndex?.length > 0 && initialEdition?.editionid) {
          let unique = [
            ...new Set([...digitalDayIndex].map((item) => item.PageTitle)),
          ];
          unique = unique?.filter(
            (v) => !initialEdition?.tocDigital.includes(v)
          );

          if (unique?.length > 0) {
            for (let z = 0; z < unique.length; z++) {
              const elem = unique[z];

              for (let i = 0; i < digitalDayIndex.length; i++) {
                const section = digitalDayIndex[i];
                if (elem == section?.PageTitle) {
                  let articles = [];
                  for (let s = 0; s < section.Views.length; s++) {
                    const view = section.Views[s];

                    articles = [...articles, ...view.Articles];
                  }
                  let obj = {
                    ...section,
                    Articles: articles,
                  };
                  delete obj.Views;
                  groupedIndexArray.push(obj);
                }
              }
            }
          }
        }

        setDigiFinalIndexData(groupedIndexArray);
      }
    } catch (error) {}
  }, [dayIndexData, initialEdition, digitalDayIndex]);

  const pageClick = async (name, index, displayArticle, isSearch) => {
    setLoading(true);
    await setCurrentPage({ ...dayIndexData[index], pageNumber: index + 1 });
    await setNextPage({
      ...dayIndexData[index + 1],
      pageNumber: index + 2,
    });
    await setItems(dayIndexData?.[index]);

    await setPropsArticles(dayIndexData?.[index]?.Articles);
    // await getRelatedArticles(name, index);

    setTimeout(() => {
      //this is because the page should get updated first to avoid zone miisplacing
      setDisplayArticle(displayArticle ?? "");
    }, 1000);
    await setIsSearch(
      { ...isSearch } ?? { search: "", from: "", articleName: "" }
    );
    await setShowPageThumb(false);
    eventIntegration({
      event: "page_view",
      eventCategory: initialEdition?.editionname,
      eventAction: "page_view",
      eventLabel: currentPage?.pageNumber,
      TOC: currentPage?.PageTitle,
      //required
      PAGE_NO: currentPage?.pageNumber,
      //required
      epaperType: selectedNewsType,
      title: articleViewData?.ArticleTitle,
      author: articleViewData?.ArticleAuthor,
      publication: initialPublication?.publicationname,
      edition: initialEdition?.editionname,
      articleUrl: window.location.href,
      url: window.location.href,
    });
  };

  const pageClickDigital = async (name, index, displayArticle, PageName) => {
    setLoading(true);
    // await setCurrentPage({ ...dayIndexData[index], pageNumber: index + 1 });
    // await setNextPage({ ...dayIndexData[index + 1], pageNumber: index + 2 });
    await setItems(dayIndexData?.[index]);

    await setPropsArticles(
      digiFinalIndexData?.filter(function (value) {
        return value?.PageTitle === PageName;
      })?.[0]?.Articles
    );

    await setDisplayArticle(displayArticle ?? "");
    // await setIsSearch(
    //   { ...isSearch } ?? { search: "", from: "", articleName: "" }
    // );
    // await setShowPageThumb(false);

    eventIntegration({
      event: "page_view",
      eventCategory: initialEdition?.editionname,
      eventAction: "page_view",
      eventLabel: currentPage?.pageNumber,
      TOC: currentPage?.PageTitle,
      //required
      PAGE_NO: currentPage?.pageNumber,
      //required
      epaperType: selectedNewsType,
      title: articleViewData?.ArticleTitle,
      author: articleViewData?.ArticleAuthor,
      publication: initialPublication?.publicationname,
      edition: initialEdition?.editionname,
      articleUrl: window.location.href,
      url: window.location.href,
    });
  };
  const changeCurrentIndex = (index) => {
    setCurrentIndexHome(index);
  };

  useEffect(() => {
    if (
      currentPublication &&
      Object.keys(currentPublication)?.length > 0 &&
      JSCookie.get("edindex")
    ) {
      let url = `/${currentPublication?.publicationname
        .split(" ")
        .join("-")
        .toLowerCase()}/${currentPublication?.editions[
        JSCookie.get("edindex")
      ]?.editionname
        .split(" ")
        .join("-")
        .toLowerCase()}`;
      if (url) setContentModalURL(url);
    }
  }, [currentPublication]);
  // const initializeGAnalytics = () => {
  //   if (
  //     publicationsData.length > 0 &&
  //     JSCookie.get("edindex") &&
  //     JSCookie.get("pubindex")
  //   ) {
  //     let trackers = [];
  //     publicationsData.forEach((item) => {
  //       const editionTrackers = item.editions.map((edition) => {
  //         return {
  //           trackingId: edition.trackingId,
  //           gaOptions: { name: `${edition.editionid}-${edition.editionname}` },
  //         };
  //       });
  //       trackers = [...trackers, ...editionTrackers];
  //     });
  //     ReactGA.initialize(trackers);
  //   }
  // };
  // useEffect(() => {
  //   initializeGAnalytics();
  // }, []);

  useEffect(() => {
    setFilteredDate(
      dateIndexData &&
        dateIndexData.length > 0 &&
        dateIndexData.filter((v) => v.Date === JSCookie.get("eddate"))?.[0]
    );
  }, [dateIndexData]);

  const getLinkJson = async (pub, edn, date) => {
    let path = `${publicationJson[process.env.REACT_APP_ENV]}/PublicationData/${
      pub?.publicationcode
    }/${edn?.editionid}/${date.Date.split("-")
      .reverse()
      .join("/")}/LinkJson/${date.Date.split("-").join("_")}_${
      edn?.editionid
    }.json?v=${date?.Timestamp}`;
    await axios
      .get(path)
      .then(async (res) => {
        setLinkJsonData(res.data);
      })
      .catch((err) => {
        console.log("getLinkJson err: ", err);
      });
  };
  const getCustomerDetails = async (ref_id) => {
    try {
      if (ref_id)
        axios
          .get(
            `${process.env.REACT_APP_SUBSCRIPTION_URL}/customer/find-by-id/${ref_id}`
          )
          .then((res) => {
            localStorage.setItem("customerDetails", JSON.stringify(res?.data));
          })
          .catch((err) => {
            console.log(err?.response?.data);
          });
    } catch (error) {
      console.log("error: ", error);
    }
  };

  //fetch bookmark data
  const fetchBookmark = () => {
    try {
      let customerDetails = localStorage.getItem("customerDetails");
      if (customerDetails && dateArray?.length > 0) {
        customerDetails = JSON.parse(customerDetails);
        let url = `${
          process.env.REACT_APP_SUBSCRIPTION_URL
        }/bookmark/get-bookmarks-by-tenant-and-customer/${
          process.env.REACT_APP_EPAPER_TENANT_ID
        }/${customerDetails?._id}/${dateArray?.join("-")}/epaper`;

        if (url && dateArray?.length > 0)
          axios
            .get(url)
            .then((response) => {
              let bookmarks = response.data;
              if (bookmarks.length) {
                bookmarks = bookmarks.map((e) => e.article_id);
                setBookmarks(bookmarks);
                bookmarks = JSON.stringify(bookmarks);
                localStorage.setItem("bookmarks", bookmarks);
              } else {
                setBookmarks([]);
                localStorage.setItem("bookmarks", JSON.stringify([]));
              }
            })
            .catch((error) => {
              console.log(error);
            });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const cleverTapprofilePush = (data, type) => {
    if (Object.keys(data).length > 0) {
      let accountStatus;
      let freetrial_start, freetrial_end, subscription_end, subscription_start;
      // function getAge(dateString) {
      //   var ageInMilliseconds = new Date() - new Date(dateString);
      //   return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365); // convert to years
      // }
      // let age = getAge(ssoInfo?.dob);
      // if (data?.access_type === "paid") {
      //   accountStatus = "Subscribed";
      //   subscription_start = moment
      //     .unix(data?.subscription_start)
      //     .format("DD-MM-YYYY");
      //   subscription_end = moment
      //     .unix(data?.subscription_end)
      //     .format("DD-MM-YYYY");
      // } else if (data?.access_type === "expired") {
      //   accountStatus = "Lapser";
      //   subscription_start = moment
      //     .unix(data?.subscription_start)
      //     .format("DD-MM-YYYY");
      //   subscription_end = moment
      //     .unix(data?.subscription_end)
      //     .format("DD-MM-YYYY");
      // } else if (data?.access_type === "ft1") {
      //   accountStatus = "FT1";
      //   freetrial_start = moment
      //     .unix(data?.subscription_start)
      //     .format("DD-MM-YYYY");
      //   freetrial_end = moment
      //     .unix(data?.subscription_end)
      //     .format("DD-MM-YYYY");
      // } else if (data.access_type === "ft2") {
      //   accountStatus = "FT2";
      //   freetrial_start = moment.unix(data?.ft2_start).format("DD-MM-YYYY");
      //   freetrial_end = moment.unix(data?.ft2_end).format("DD-MM-YYYY");
      // }

      let profileInfo = {
        "User Id": data?.ref_id ?? "",
        "First Name": data?.firstname,
        "Last Name": data?.lastname[0],
        "Email Id": data?.email ?? "",

        Identity: data?.ref_id ?? "",

        "Mobile Number": data?.phone ?? "",
        "Account Status": data?.typeofuser ?? "",
        "Plan Type": data?.typeofuser ?? "",

        // "Free Trial Start date": freetrial_start ?? "",
        // "Free Trial End date": freetrial_end ?? "",
        // "Subscription Start Date": subscription_start ?? "",
        // "Subscription End Date": subscription_end ?? "",
        // City: !["Unknown", "unknown"].includes(ssoInfo?.city)
        //   ? ssoInfo?.city
        //   : "",
        // Gender: ssoInfo?.gender ?? "",
        // Age: age ?? "",
        // "Payment Mode": "",
        // "Is a Repeat Subscriber":
        //   jsCookie.get("is_reapeated_subscriber") ?? "No",
      };
      // if (ssoInfo?.dob) {
      //   let dob = moment(ssoInfo?.dob).format("YYYY-MM-DD");

      //   profileInfo["dob"] = dob;
      // }

      // clevertap user Attributes push

      localStorage.setItem("clevertap_profile", JSON.stringify(profileInfo));
      profilePush(profileInfo, type);
    } else {
      let profileInfo = {
        "User Id": "",
        "First Name": "",
        "Last Name": "",
        "Email Id": "",
        Identity: "",
        "Mobile Number": "",
        "Account Status": "Anon",
        "Plan Type": "Anon",
      };
      localStorage.setItem("clevertap_profile", JSON.stringify(profileInfo));
      profilePush(profileInfo, type);
    }
    // setting cookie to check clevertap_profile_update
    const date = new Date();
    const midnight = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      23,
      59,
      59
    );

    JSCookie.set("clevertap_profile_update", true, {
      expires: midnight,
    });
  };

  const [loginFrom, setLoginFrom] = useState("");
  const [firstTimeLogin, setFirstTimeLogin] = useState(
    JSCookie.get("firstTimeLogin") ? true : false
  );

  // let active_day = "Thu Feb 15 2023 23:56:15 GMT+0530";
  // console.log("active_day: ", active_day);

  // let today = "Thu Feb 16 2023 23:59:23 GMT+0530";
  // console.log("today: ", today);
  // console.log("@@today: ", dayjs(today).isAfter(dayjs(active_day), "day"));

  useEffect(() => {
    if (JSCookie.get("pgsygina") === undefined) {
      let active_day = localStorage.getItem("active_day");
      let today = new Date();
      if (
        active_day === null ||
        dayjs(today).isAfter(dayjs(active_day), "day")
      ) {
        eventIntegration({
          event: "active_days",
          eventCategory: "active_days",
          eventAction: "active_days",
          eventLabel: "active_days",
          TOC: currentPage?.PageTitle,
          //required
          PAGE_NO: currentPage?.pageNumber,
          //required
          epaperType: selectedNewsType,
          title: articleViewData?.ArticleTitle,
          author: articleViewData?.ArticleAuthor,
          publication: initialPublication?.publicationname,
          edition: initialEdition?.editionname,
          articleUrl: window.location.href,
          act_unique: "1",
        });
        localStorage.setItem("active_day", new Date());
      }
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth > 700) {
      window.addEventListener("contextmenu", (event) => event.preventDefault());
    }
    // this url will be used once it is live

    // const url = `${
    //   process.env.REACT_APP_EPAPERSYGINA_URL
    // }/cust_sub_date.html?getdate=true&set_ck=${JSCookie.get("pgsygina")}`;
    const url = `${
      process.env.REACT_APP_PAYWALL_URL
    }/cust_sub_date.html?getdate=true&set_ck=${JSCookie.get("pgsygina")}`;
    JSCookie.get("pgsygina") &&
      axios
        .get(`${url}`)
        .then((res) => {
          setUserInfo(res.data);
          if (res?.data?.typeofuser?.length > 0) {
            //removing blocker cookie to make sure blocker banner is hidden
            document.cookie = `blocker_login=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=timesgroup.com;path=/`;
          }

          if (!firstTimeLogin && res && res.data.day_active) {
            // eventIntegration({
            //   event: "login_completed",
            //   eventCategory: "login",
            //   eventAction: "login_completed",
            //   eventLabel: JSCookie.get("loginfrom"),
            //   TOC: currentPage?.PageTitle,
            //   //required
            //   PAGE_NO: currentPage?.pageNumber,
            //   //required
            //   epaperType: selectedNewsType,
            //   title: articleViewData?.ArticleTitle,
            //   author: articleViewData?.ArticleAuthor,
            //   publication: initialPublication?.publicationname,
            //   edition: initialEdition?.editionname,
            //   articleUrl: window.location.href,
            // });
            JSCookie.set("firstTimeLogin", true, {
              expires: 1,
            });
          }
          let active_day = localStorage.getItem("active_day");
          let today = new Date();

          if (res.data && res.data.day_active === false) {
            if (
              active_day === null ||
              dayjs(today).isAfter(dayjs(active_day), "day")
            ) {
              eventIntegration({
                event: "active_days",
                eventCategory: "active_days",
                eventAction: "active_days",
                eventLabel: "active_days",
                TOC: currentPage?.PageTitle,
                epaperType: selectedNewsType,
                title: articleViewData?.ArticleTitle,
                author: articleViewData?.ArticleAuthor,
                publication: initialPublication?.publicationname,
                edition: initialEdition?.editionname,
                articleUrl: window.location.href,
                act_unique: "2",
              });

              let newUrl = `${process.env.REACT_APP_PAYWALL_URL}/cust_sub_date.html?action=update_days&ref_id=${res.data.ref_id}`;
              axios.get(newUrl).then((response) => {});

              localStorage.setItem("active_day", new Date());
            }
          }
          if (
            res.data &&
            res.data.day_active === false &&
            dayjs(today).isAfter(dayjs(active_day), "day")
          ) {
            let newUrl = `${process.env.REACT_APP_PAYWALL_URL}/cust_sub_date.html?action=update_days&ref_id=${res.data.ref_id}`;
            axios.get(newUrl).then((response) => {});
          }

          if (res.data.response === "success") {
            localStorage.setItem("manifest_allowed", true);
          }
          getCustomerDetails(res?.data?.ref_id);
          cleverTapprofilePush(res?.data, "profilepush");
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    if (localStorage.getItem("bookmarks"))
      setBookmarks(JSON.parse(localStorage.getItem("bookmarks")));

    if (
      localStorage.getItem("customerDetails") &&
      !localStorage.getItem("bookmarks")
    )
      fetchBookmark();
  }, []);

  useEffect(() => {
    if (JSCookie.get("pgsygina") === undefined) {
      JSCookie.remove("firstTimeLogin");
    }
  }, []);

  useEffect(() => {
    if (
      !JSCookie.get("selectionHp") &&
      initialEdition?.digitalView === true &&
      !JSCookie.get("selectnews")
    ) {
      setDisplaySectionPage(true);
    } else if (initialEdition?.digitalView === false) {
      setDisplaySectionPage(false);
      JSCookie.set("selectnews", "e-paper", { expires: 365 });
      setSelectedNewsType("e-paper");
    } else if (initialEdition?.digitalView === true) {
      if (JSCookie.get("selectnews")) {
        if (localStorage.getItem("userPref")) {
          let array = JSON.parse(localStorage.getItem("userPref"));

          let indexOfPub = array.findIndex(
            (v) => v.pub === initialPublication?.publicationcode
          );

          if (indexOfPub !== -1) {
            let ednIndex = array[indexOfPub].visits?.findIndex(
              (x) => x.edn == initialEdition.editionname
            );

            if (ednIndex !== -1) {
              JSCookie.set(
                "selectnews",
                array[indexOfPub].visits[ednIndex].view,
                {
                  expires: 365,
                }
              );
              setSelectedNewsType(array[indexOfPub].visits[ednIndex].view);
            } else {
              // JSCookie.set("selectnews", "digital", { expires: 365 });
              // setSelectedNewsType("digital");
            }
          } else {
            // JSCookie.set("selectnews", "digital", { expires: 365 });
            // setSelectedNewsType("digital");
          }
        }
        // else {
        // JSCookie.set("selectnews", "digital", { expires: 365 });
        // setSelectedNewsType("digital");
        // }
      } else {
        JSCookie.set("selectnews", "digital", { expires: 365 });
        setSelectedNewsType("digital");
      }
    }
  }, [initialEdition, initialPublication]);

  // useEffect(() => {
  //   setLastVisited(initialPublication, initialEdition);
  // }, [selectedNewsType]);
  // useEffect(() => {
  // setLastVisited( initialPublication,initialEdition)
  // }, [initialEdition, initialPublication, selectedNewsType]);

  function setLastVisited(publication, edition, type) {
    try {
      if (
        publication?.publicationcode &&
        edition?.editionname &&
        JSCookie.get("selectnews")
      ) {
        if (localStorage.getItem("userPref")) {
          let array = JSON.parse(localStorage.getItem("userPref"));

          let indexOfPub = array.findIndex(
            (v) => v.pub === publication?.publicationcode
          );
          if (indexOfPub !== -1) {
            let ednIndex = array[indexOfPub].visits?.findIndex(
              (x) => x.edn == edition.editionname
            );

            if (ednIndex !== -1) {
              let visits = [...array?.[indexOfPub]?.visits];

              visits = visits.filter((z) => z.edn !== edition?.editionname);
              visits = [
                ...visits,
                {
                  edn: edition?.editionname,
                  view: type ?? JSCookie.get("selectnews"),
                },
              ];

              array[indexOfPub].visits = visits;
            } else {
              array[indexOfPub].visits = [
                ...array?.[indexOfPub]?.visits,
                {
                  edn: edition?.editionname,
                  view: type ?? JSCookie.get("selectnews"),
                },
              ];
            }
            // array[indexOfPub].visits = [
            //   ...new Map(
            //     array[indexOfPub].visits.map((item) => [item["edn"], item])
            //   ).values(),
            // ];

            localStorage.setItem("userPref", JSON.stringify(array));
          } else {
            array.push({
              pub: publication?.publicationcode,
              visits: [
                {
                  edn: edition?.editionname,
                  view: type ?? JSCookie.get("selectnews"),
                },
              ],
            });

            localStorage.setItem("userPref", JSON.stringify(array));
          }
        } else {
          let array = [];
          array.push({
            pub: publication?.publicationcode,
            visits: [
              {
                edn: edition?.editionname,
                view: type ?? JSCookie.get("selectnews"),
              },
            ],
          });

          localStorage.setItem("userPref", JSON.stringify(array));
        }
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }
  const redirectToNewPath = async (publication) => {
    if (localStorage.getItem("userPref")) {
      let array = JSON.parse(localStorage.getItem("userPref"));

      let indexOfPub = array.findIndex(
        (v) => v.pub === publication?.publicationcode
      );
      if (indexOfPub !== -1) {
        if (array[indexOfPub].visits?.length > 0) {
          let lastVisitedEdn = array[indexOfPub].visits.slice(-1);

          if (lastVisitedEdn?.[0]?.edn) {
            setDisplaySectionPage(
              lastVisitedEdn?.[0]?.view == "digital" ? true : false
            );
            JSCookie.set("selectnews", lastVisitedEdn?.[0]?.view, {
              expires: 365,
            });
            setSelectedNewsType(lastVisitedEdn?.[0]?.view);
            // if (lastVisitedEdn?.[0]?.view == "digital") getDigitalDayIndex();
            return lastVisitedEdn?.[0]?.edn;
          } else {
            let ednIndex = publication.editions?.filter(
              (s) => s.editionid == lastVisitedEdn?.[0]?.edn
            );
            // console.log("ednIndex: ", ednIndex);
            return ednIndex?.length > 0
              ? ednIndex[0]?.editionname
              : publication?.editions?.[0]?.editionname;
          }
        } else {
          // console.log("no visits else: ");
          return publication?.editions?.[0]?.editionname;
        }
      } else {
        // console.log("publication not found: ");
        return publication?.editions?.[0]?.editionname;
      }
    } else {
      // console.log("localstorage item not found: ");
      return publication?.editions?.[0]?.editionname;
    }
  };

  const digitalInitalDate = "17-08-2022".split("-").reverse().join("-");

  // const startDateAd = "09-13-2023";
  // const endDateAd = "09-13-2023";

  // useEffect(() => {
  //   // Function to check if the current date and time is within the interval
  //   const isCurrentDateWithinInterval = () => {
  //     const currentDate = dayjs(); // Get the current date and time with dayjs
  //     const startDate = dayjs(startDateAd, "DD-MM-YYYY");
  //     const endDate = dayjs(endDateAd, "DD-MM-YYYY");

  //     return currentDate.isBetween(startDate, endDate, null, "[]"); // Check if currentDate is within the interval
  //     // return currentDate > startDate && currentDate < endDate;
  //   };

  //   // Check if the current date and time is within the interval
  //   const withinInterval = isCurrentDateWithinInterval();

  //   // Update state based on whether the current date and time is within the interval
  //   setAllowInterStitial(withinInterval);
  // }, [startDateAd, endDateAd]);

  useEffect(() => {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const currentDate = `${day}-${month}-${year}`;
    if (currentDate == "14-9-2023") {
      console.log("t", currentDate);

      setAllowInterStitial(true);
    }
  }, []);

  //login and subscribe alerts blockers

  useEffect(() => {
    if (!JSCookie.get("andre") && !JSCookie.get("pgsygina")) {
      if (
        process.env.REACT_APP_BLOCKER_DURATION &&
        Number(process.env.REACT_APP_BLOCKER_DURATION) > 0
      ) {
        if (!JSCookie.get("blocker_login")) {
          const today = new Date();
          const currentDate = new Date();
          currentDate.setSeconds(
            currentDate.getSeconds() +
              Number(process.env.REACT_APP_BLOCKER_DURATION)
          );
          today.setHours(23);
          today.setMinutes(59);
          today.setSeconds(59);
          // JSCookie.set("blocker_login", currentDate, {
          //   domain: "timesgroup.com",
          //   path: "/",
          //   expires: 1,
          // });
          document.cookie = `blocker_login=${currentDate}; expires=${today.toUTCString()}; domain=timesgroup.com;path=/`;
          // }
          // else {
          //   // JSCookie.remove("blocker_login", {
          //   //   domain: "timesgroup.com",
          //   //   path: "/",
          //   // });
          //   document.cookie = `blocker_login=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=timesgroup.com;path=/`;

          //   props.history.push("/home");
          // }
        }
      } else {
        // JSCookie.remove("blocker_login", {
        //   domain: "timesgroup.com",
        //   path: "/",
        // });
        document.cookie = `blocker_login=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=timesgroup.com;path=/`;

        history.push("/home");
      }
    } else if (JSCookie.get("andre") || JSCookie.get("pgsygina")) {
      document.cookie = `blocker_login=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=timesgroup.com;path=/`;
    }

    if (!JSCookie.get("andre") && JSCookie.get("pgsygina")) {
      setLoading(true);
      const checkCookie = () => {
        axios
          .get(
            `${
              process.env.REACT_APP_PAYWALL_URL
            }/cookie_status.html?chk=${JSCookie.get("pgsygina")}`
          )
          .then(async (res) => {
            if (res.data === "valid") {
              // JSCookie.remove("blocker_login", {
              //   domain: "timesgroup.com",
              //   path: "/",
              // });
              document.cookie = `blocker_login=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=timesgroup.com;path=/`;

              const in8hrs = 1 / 3;
              JSCookie.set("andre", JSCookie.get("pgsygina"), {
                domain: "timesgroup.com",
                path: "/",
                expires: 1,
              });

              await updateCookies();
              history.push(
                `/${publicationJson?.Publications?.[
                  JSCookie.get("pubindex")
                ]?.publicationname
                  ?.split(" ")
                  .join("-")
                  .toLowerCase()}/${publicationJson?.Publications?.[
                  JSCookie.get("pubindex")
                ]?.editions[JSCookie.get("edindex")]?.editionname
                  ?.split(" ")
                  .join("-")
                  .toLowerCase()}`
              );
              setLoading(false);
            } else {
              setIsOpenSubscribeAlert(true); //later use

              setLoading(false);
            }
          });
      };

      checkCookie();
    }
  });

  useEffect(() => {
    if (JSCookie.get("blocker_login")) {
      setInterval(() => {
        if (
          new Date(JSCookie.get("blocker_login")).getTime() <
          new Date().getTime()
        ) {
          const redirectToLoginURL = `http://${
            window.location.hostname
          }/${publicationJson?.Publications?.[
            JSCookie.get("pubindex")
          ]?.publicationname
            ?.split(" ")
            .join("-")
            .toLowerCase()}/${publicationJson?.Publications?.[
            JSCookie.get("pubindex")
          ]?.editions[JSCookie.get("edindex")]?.editionname
            ?.split(" ")
            .join("-")
            .toLowerCase()}`;
          const homeURL = `http://${window.location.hostname}/home`;
          const encodedURI =
            `${process.env.REACT_APP_PAYWALL_URL}/home.html?From=` +
            encodeURIComponent(
              JSCookie.get("pubindex") && JSCookie.get("edindex")
                ? redirectToLoginURL
                : homeURL
            );
          //todo
          // window.location.assign(encodedURI);
          setIsOpenSubscribeAlert(true); //for later use
        }
      }, 5000);
      //process.env.REACT_APP_BLOCKER_DURATION * 10
    }
  });

  const hasOneDayPassed = () => {
    // get today's date. eg: "7/37/2007"
    var date = new Date().toLocaleDateString();

    // if there's a date in localstorage and it's equal to the above:
    // inferring a day has yet to pass since both dates are equal.
    if (localStorage.yourapp_date == date) return false;

    // this portion of logic occurs when a day has passed
    localStorage.yourapp_date = date;
    return true;
  };

  useEffect(() => {
    if (!JSCookie.get("timeLimit")) {
      const today = new Date();
      const currentDate = new Date();
      currentDate.setSeconds(
        currentDate.getSeconds() +
          Number(process.env.REACT_APP_BLOCKER_DURATION)
      );

      today.setHours(23);
      today.setMinutes(59);
      today.setSeconds(59);
      JSCookie.set("timeLimit", currentDate, {
        domain: "timesgroup.com",
        path: "/",
        expires: 1,
      });
    }
    if (!JSCookie.get("timeLimit")) {
      setInterval(() => {
        // if (
        //   new Date(JSCookie.get("timeLimit")).getTime() < new Date().getTime()
        // ) {
        // setIsOpenSubscribeAlert(true);
        // }
      }, 3000);
    }
    if (!hasOneDayPassed() && !JSCookie.get("timeLimit")) {
      return false;
    } else if (!JSCookie.get("timeLimit")) {
      setTimeout(() => {
        setIsOpenSubsReminder(true);
      }, 120000); //after 2 min every day
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      eventIntegration({
        event: "page_view",
        eventCategory: window.location.href?.split("/")[4] ?? "",
        eventAction: "page_view",
        eventLabel: currentPage?.pageNumber,
        TOC: currentPage?.PageTitle,
        //required
        PAGE_NO: currentPage?.pageNumber,
        //required
        epaperType: selectedNewsType,
        title: articleViewData?.ArticleTitle,
        author: articleViewData?.ArticleAuthor,
        publication: initialPublication?.publicationname,
        edition: initialEdition?.editionname,
        articleUrl: window.location.href,
        url: window.location.href,
      });
    }, 0);
  }, []);

  //Storing last used publication and edition info for the user
  useEffect(() => {
    if (
      userInfo?.typeofuser?.length > 0 &&
      initialPublication?.publicationname?.length > 0 &&
      initialEdition.editionname?.length > 0
    ) {
      userPubEdData(initialPublication, initialEdition);
    }
  }, [initialPublication, initialEdition, userInfo]);
  return (
    <AppContext.Provider
      value={{
        isPopularDrawerOpen,
        setIsPopularDrawerOpen,
        isFitToHeight,
        setIsFitToHeight,
        isFitToWidth,
        setisFitToWidth,
        articles,
        setArticles,
        width,
        setWidth,
        setHeight,
        vw,
        calculateNewHeight,
        vh,
        refactorHighlight,
        isOpenArticleModal,
        toggleArticleViewModal,
        articleViewData,
        articleViewName,
        setArticleViewName,
        setArticleViewData,
        setArticleViewDataLinked,
        relatedArticleData,
        setRelatedArticleData,
        articleViewDataLinked,
        articleViewNameLinked,
        setArticleViewNameLinked,
        toggle,
        isPanoramaPage,
        setIsPanoramaPage,
        isOpen2,
        isPanoramaPage2,
        setIsPanoramaPage2,
        setIsOpen2,
        toggleShare,
        isOpenMobArticle,
        ClickAnywhereClose,
        fit2page,
        setFit2page,
        articlesPage2,
        setArticlesPage2,
        widthPage2,
        setWidthPage2,
        refactorHighlightPage2,
        print,
        isLoading,
        showLoader,
        setShowLoader,
        setIsLoading,
        multiplier,
        setMultiplier,
        isLandingPage,
        setIsLandingPage,
        mainPageLOader,
        setMainPageLoader,
        s3Source: publicationJson[process.env.REACT_APP_ENV],
        currArticleIndex,
        setCurrArticleIndex,
        publicationJson: publicationJson.Publications,
        updateInsight,
        views,
        shares,
        totalShareCount,
        setTotalShareCount,
        isRegional,
        setIsRegional,
        imageUrl,
        setImageUrl,
        activeTabName,
        setActiveTabName,
        linkJsonURL,
        setLinkJsonURL,
        hasInfoZone,
        setHasInfoZone,
        ratioMainPage,
        setRatioMainPage,
        fit2pagesArray,
        setFit2pagesArray,
        isOpenArchive,
        setIsOpenArchive,
        TTS,
        setTTS,
        state,
        setState,

        //from app.js
        loading,
        setLoading,
        publicationsData,
        setPublicationsData,
        editionData,
        setEditionsData,
        initialPublication,
        setInitialPublication,
        initialEdition,
        setInitialEdition,
        todaysDate,
        setTodaysDate,
        dateArray,
        setDateArray,
        dateIndexData,
        setDateIndexData,
        dayIndexData,
        setDayIndexData,
        sectionData,
        setSectionData,
        currentPage,
        setCurrentPage,
        nextPage,
        setNextPage,
        displayArticle,
        setDisplayArticle,
        logo,
        setLogo,
        items,
        setItems,
        isSearch,
        setIsSearch,
        showPageThumb,
        setShowPageThumb,
        currentIndexHome,
        setCurrentIndexHome,
        currentPublication,
        setCurrentPublication,
        isSecondPage,
        setIsSecondPage,
        contentModalURL,
        setContentModalURL,
        themeColor,
        setThemeColor,
        propsArticles,
        setPropsArticles,
        favIcon,
        setFavicon,
        mostViewed,
        setMostViewed,
        mostShared,
        setMostShared,
        getPublicationsData,
        handlePublication,
        handleEdition,
        handleDate,
        pageClick,
        setLogoAndTheme,
        filteredDate,
        setFilteredDate,
        linkJsonData,
        setLinkJsonData,
        articleLoading,
        setArticleLoading,
        setZoom,
        zoom,
        updateCookies,
        checkHeight,
        setCheckHeight,
        todayCurrentDate,
        isOpenContactModal,
        setIsOpenContactModal,
        isEnableAds,
        setIsEnableAds,
        isEnableAnalytics,
        setIsEnableAnalytics,
        isOpenFeedBackModal,
        setIsOpenFeedBackModal,
        activeTabMenu,
        setActiveTabMenu,
        paperView,
        setPaperView,
        isOpenAppIntro,
        setIsOpenAppIntro,
        isIntroStart,
        setIsIntroStart,
        isOpenTrialInfo,
        setIsOpenTrialInfo,
        isOpenSubscribeAlert,
        setIsOpenSubscribeAlert,
        isOpenSubsReminder,
        setIsOpenSubsReminder,
        userInfo,
        setUserInfo,
        selectedNewsType,
        setSelectedNewsType,
        isOpenThumbZoom,
        setIsOpenThumbZoom,
        selectSection,
        setSelectSection,
        isOpenDigitalArticleModal,
        setIsOpenDigitalArticleModal,
        digiDayIndexData,
        setDigiDayIndexData,
        digiFinalIndexData,
        setDigiFinalIndexData,
        moreFromSection,
        setMoreFromSection,
        pageClickDigital,
        displaySelectionPage,
        setDisplaySectionPage,
        activeDigiToc,
        setActiveDigiToc,
        bookmarks,
        setBookmarks,
        fetchBookmark,
        isArticleViewOpen,
        setIsArticleViewOpen,
        digitalInitalDate,
        digitalDayIndex,
        moreFromArticles,
        setMoreFromArticles,
        redirectToNewPath,
        setLastVisited,
        stickyNav,
        setStickyNav,
        searchFromAdvanced,
        setSearchFromAdvanced,
        isOpenUpdate,
        setIsOpenUpdate,
        prevScrollPositionRef,
        prevPinchZoomStatusRef,
        resetScroll,
        setResetScroll,
        isPanoramaPageView,
        setIsPanoramaPageView,
        isPinchZoom,
        setIsPinchZoom,
        showFloatingNav,
        setShowFloatingNav,
        isBPNActive,
        setisBPNActive,
        isOpenSearchArticleModal,
        setIsOpenSearchArticleModal,
        showHeader,
        setShowHeader,
        openTocMenu,
        setOpenTocMenu,
        openTocMenuMob,
        setOpenTocMenuMob,
        sidebar,
        setSidebar,
        showButtonView,
        setShowButtonView,
        pageNextPrevClick,
        setPageNextPrevClick,
        plaerLoading,
        setPlayerLoading,
        loginFrom,
        setLoginFrom,
        isOpenExpiredAlert,
        setIsOpenExpiredAlert,
        dateIndexDataArchive,
        setDateIndexDataArchive,
        ttsMp3,
        setTtsMp3,
        openAudioTray,
        setIsOpenAudioTray,
        showImgAgency,
        setShowImgAgency,
        stepsEnabled,
        setstepsEnabled,
        startNavigate,
        firstViewValueDig,
        setFirstViewValueDig,
        isOpenInstaStories,
        setIsOpenInstaStories,
        isOpenVideoAd,
        setIsOpenVideoAd,
        currentIndexStory,
        setCurrentIndexStory,
        graphsArticle,
        setGraphsArticle,
        isOpenSlideShow,
        setIsOpenSlideShow,
        isOpenSlikePlayer,
        setIsOpenSlikePlayer,
        isOpenSlikePlayerView,
        setIsOpenSlikePlayerView,
        isOpenSlikePlayerDigital,
        setisOpenSlikePlayerDigital,
        allPlayersDigi,
        setAllPlayersDigi,
        openSlideCard,
        setOpenSlideCard,
        articleNameSlikeView,
        setArticleNameSlikeView,
        iosGraphData,
        setIosGraphData,
        allowedDates,
        setAllowedDates,
        newLinkArticles,
        setNewLinkArticles,
        mergedLinkArrays,
        setMergedLinkArrays,
        allowedIntervalArch,
        setAllowedIntervalArch,
        extractDateArch,
        articleTextView,
        setArticleTextView,
        findAllArticleDetails,
        setSearchPageTitle,
        allowInterStitial,
        setDownloadPrint,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
